import React, { useEffect, useState } from 'react';
import './Disbursement.css';

const CACHE_KEY = 'disbursementData';
const CACHE_EXPIRY = 60000; // 1 minute

const Disbursement = () => {
  const [loanRequests, setLoanRequests] = useState([]);
  const [collectors, setCollectors] = useState({});
  const [branches, setBranches] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [filter, setFilter] = useState({
    firstname: '',
    lastname: '',
    date: '',
    branch: '',
    collector: ''
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDisbursementData = async () => {
      try {
        setLoading(true);
        // Fetch loan requests
        const loanRequestsResponse = await fetch('https://srishticooperativesociety.com/api/loan_requests');
        const loanRequestsData = await loanRequestsResponse.json();
        const approvedRequests = loanRequestsData.filter(req => req.approval_status === 'approved');

        // Fetch collectors' details
        const collectorIds = [...new Set(approvedRequests.map(req => req.collector_assigned))];
        const collectorDataPromises = collectorIds.map(id =>
          fetch(`https://srishticooperativesociety.com/api/admins/${id}`).then(response => response.json())
        );
        const collectorData = await Promise.all(collectorDataPromises);
        const collectorMap = collectorData.reduce((acc, curr, idx) => {
          acc[collectorIds[idx]] = curr.name;
          return acc;
        }, {});

        // Fetch branches
        const branchesResponse = await fetch('https://srishticooperativesociety.com/api/branches');
        const branchesData = await branchesResponse.json();

        // Save data to cache
        const disbursementData = {
          loanRequests: approvedRequests,
          collectors: collectorMap,
          branches: branchesData,
          timestamp: Date.now(),
        };
        localStorage.setItem(CACHE_KEY, JSON.stringify(disbursementData));

        setLoanRequests(approvedRequests);
        setCollectors(collectorMap);
        setBranches(branchesData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching disbursement data:', error);
      }
    };

    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      const isCacheExpired = Date.now() - parsedData.timestamp > CACHE_EXPIRY;

      setLoanRequests(parsedData.loanRequests);
      setCollectors(parsedData.collectors);
      setBranches(parsedData.branches);

      if (isCacheExpired) {
        fetchDisbursementData();
      } else {
        setLoading(false);
        const timerId = setTimeout(fetchDisbursementData, CACHE_EXPIRY - (Date.now() - parsedData.timestamp));
        return () => clearTimeout(timerId);
      }
    } else {
      fetchDisbursementData();
    }
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevFilter => ({ ...prevFilter, [name]: value }));
  };

  const applyFilters = () => {
    let filtered = loanRequests;

    if (filter.firstname) {
      filtered = filtered.filter(req => req.firstname.toLowerCase().includes(filter.firstname.toLowerCase()));
    }

    if (filter.lastname) {
      filtered = filtered.filter(req => req.lastname.toLowerCase().includes(filter.lastname.toLowerCase()));
    }

    if (filter.date) {
      filtered = filtered.filter(req => new Date(req.posted_at).toLocaleDateString() === new Date(filter.date).toLocaleDateString());
    }

    if (filter.branch) {
      filtered = filtered.filter(req => req.branch_id === parseInt(filter.branch));
    }

    if (filter.collector) {
      filtered = filtered.filter(req => req.collector_assigned === parseInt(filter.collector));
    }

    setFilteredRequests(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [filter, loanRequests]);

  const totalAmount = loanRequests.reduce((sum, req) => sum + parseFloat(req.amount), 0);

  return (
    <div className="disbursement-container">
      <h1>Loan Disbursement</h1>

      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="total-disbursement">
            <strong>Total Disbursement Amount:</strong> ₹{totalAmount.toFixed(2)}
          </div>

          <div className="filters">
            <input 
              type="text" 
              placeholder="Filter by Firstname" 
              name="firstname" 
              value={filter.firstname} 
              onChange={handleFilterChange} 
            />
            <input 
              type="text" 
              placeholder="Filter by Lastname" 
              name="lastname" 
              value={filter.lastname} 
              onChange={handleFilterChange} 
            />
            <input 
              type="date" 
              name="date" 
              value={filter.date} 
              onChange={handleFilterChange} 
            />

            <select name="branch" value={filter.branch} onChange={handleFilterChange}>
              <option value="">Filter by Branch</option>
              {branches.map(branch => (
                <option key={branch.id} value={branch.id}>{branch.branchname}</option>
              ))}
            </select>

            <select name="collector" value={filter.collector} onChange={handleFilterChange}>
              <option value="">Filter by Collector</option>
              {Object.entries(collectors).map(([id, name]) => (
                <option key={id} value={id}>{name}</option>
              ))}
            </select>
          </div>

          <table className="disbursement-table">
            <thead>
              <tr>
                <th>Disbursement Amount</th>
                <th>To</th>
                <th>Branch</th>
                <th>Date</th>
                <th>Field Officer</th>
              </tr>
            </thead>
            <tbody>
              {filteredRequests.map(req => {
                const branch = branches.find(b => b.id === req.branch_id);
                return (
                  <tr key={req.id}>
                    <td>{req.amount}</td>
                    <td>{req.firstname} {req.lastname}</td>
                    <td>{branch ? branch.branchname : 'Loading...'}</td>
                    <td>{new Date(req.posted_at).toLocaleDateString()}</td>
                    <td>{collectors[req.collector_assigned] || 'Loading...'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default Disbursement;
