import React, { useEffect, useState } from "react";
import "./LoanRequests.css";

const CACHE_EXPIRATION_TIME = 60000; // 1 minute
const formatDate = (dateString) => {
  const [year, month, day] = dateString.split(" ")[0].split("-");
  return `${day}/${month}/${year}`;
};
const LoanRequests = () => {
  const [loanRequests, setLoanRequests] = useState([]);
  const [collectorNames, setCollectorNames] = useState({});
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [currentLoan, setCurrentLoan] = useState(null);
  const [editData, setEditData] = useState({});
  const [availableCollectors, setAvailableCollectors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const fetchLoanRequests = async () => {
      const cachedData = JSON.parse(localStorage.getItem("loanRequestsCache"));
      const now = Date.now();

      if (cachedData && now - cachedData.timestamp < CACHE_EXPIRATION_TIME) {
        setLoanRequests(cachedData.data);
        fetchCollectorNames(cachedData.data);
      } else {
        try {
          const response = await fetch("https://srishticooperativesociety.com/api/loan_requests");
          const data = await response.json();
          setLoanRequests(data);

          // Save to cache
          localStorage.setItem(
            "loanRequestsCache",
            JSON.stringify({ data, timestamp: now })
          );

          fetchCollectorNames(data);
        } catch (error) {
          console.error("Error fetching loan requests:", error);
        }
      }
    };

    fetchLoanRequests();
    fetchAllCollectors();
  }, []);

  const fetchCollectorNames = (loans) => {
    const uniqueCollectorIds = [...new Set(loans.map((loan) => loan.collector_assigned))];

    uniqueCollectorIds.forEach(async (id) => {
      const cachedCollector = JSON.parse(localStorage.getItem(`collector_${id}`));
      const now = Date.now();

      if (cachedCollector && now - cachedCollector.timestamp < CACHE_EXPIRATION_TIME) {
        setCollectorNames((prev) => ({ ...prev, [id]: cachedCollector.name }));
      } else {
        try {
          const response = await fetch(`https://srishticooperativesociety.com/api/admins/${id}`);
          const data = await response.json();
          setCollectorNames((prev) => ({ ...prev, [id]: data.name }));

          // Save to cache
          localStorage.setItem(
            `collector_${id}`,
            JSON.stringify({ name: data.name, timestamp: now })
          );
        } catch (error) {
          console.error(`Error fetching collector with ID ${id}:`, error);
        }
      }
    });
  };

  const fetchAllCollectors = async () => {
    const cachedCollectors = JSON.parse(localStorage.getItem("allCollectorsCache"));
    const now = Date.now();

    if (cachedCollectors && now - cachedCollectors.timestamp < CACHE_EXPIRATION_TIME) {
      setAvailableCollectors(cachedCollectors.data);
    } else {
      try {
        const response = await fetch("https://srishticooperativesociety.com/api/admins");
        const data = await response.json();
        setAvailableCollectors(data);

        // Save to cache
        localStorage.setItem(
          "allCollectorsCache",
          JSON.stringify({ data, timestamp: now })
        );
      } catch (error) {
        console.error("Error fetching all collectors:", error);
      }
    }
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentLoans = loanRequests.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(loanRequests.length / itemsPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // Edit Loan
  const handleEdit = (loan) => {
    setCurrentLoan(loan);
    setEditData(loan);
    setIsEditPopupOpen(true);
  };

  const closeEditPopup = () => {
    setIsEditPopupOpen(false);
    setCurrentLoan(null);
    setEditData({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditSubmit = async () => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${currentLoan.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editData),
      });
      const updatedLoan = await response.json();
      setLoanRequests((prev) =>
        prev.map((loan) => (loan.id === currentLoan.id ? { ...loan, ...editData } : loan))
      );
      closeEditPopup();
    } catch (error) {
      console.error("Error updating loan:", error);
    }
  };

  // Delete Loan
  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        setLoanRequests((prev) => prev.filter((loan) => loan.id !== id));
      } else {
        console.error("Failed to delete loan:", response.status);
      }
    } catch (error) {
      console.error("Error deleting loan:", error);
    }
    setIsDeletePopupOpen(false);
  };

  const openDeletePopup = (loan) => {
    setCurrentLoan(loan);
    setIsDeletePopupOpen(true);
  };

  const closeDeletePopup = () => {
    setIsDeletePopupOpen(false);
    setCurrentLoan(null);
  };

  return (
    <div className="loan-requests-container">
      <h1 className="title">Loan Requests</h1>
      <table className="loan-requests-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Loan Type</th>
            <th>Amount</th>
            <th>Interest Rate</th>
            <th>Date</th>
            <th>Approval Status</th>
            <th>Collector Assigned</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentLoans.map((loan, index) => (
            <tr key={loan.id}>
              <td>{indexOfFirstItem + index + 1}</td>
              <td>{`${loan.firstname} ${loan.lastname}`}</td>
              <td>{loan.loan_type}</td>
              <td>₹{loan.amount}</td>
              <td>{loan.interest_rate}%</td>
              <td>{formatDate(loan.posted_at)}</td>
              <td>{loan.approval_status}</td>
              <td>{collectorNames[loan.collector_assigned] || "Loading..."}</td>
              <td>
                <button className="edit-button" onClick={() => handleEdit(loan)}>
                  Edit
                </button>
                <button
                  className="delete-button"
                  onClick={() => openDeletePopup(loan)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            className={`pagination-button ${
              currentPage === i + 1 ? "active" : ""
            }`}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </button>
        ))}
      </div>

      {/* Edit Popup */}
      {isEditPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Edit Loan</h2>
            <label>
  Loan Type:
  <select
    name="loan_type"
    value={editData.loan_type || ""}
    onChange={handleInputChange}
  >
    <option value="">Select Loan Type</option>
    <option value="Business Loan">Business Loan</option>
    <option value="Car Loan">Car Loan</option>
    <option value="Personal Loan">Personal Loan</option>
    <option value="Gold Loan">Gold Loan</option>
    <option value="Home Loan">Home Loan</option>
  </select>
</label>
            <label>
              Amount:
              <input
                type="number"
                name="amount"
                value={editData.amount || ""}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Duration (Months):
              <input
                type="number"
                name="duration"
                value={editData.duration || ""}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Interest Rate (%):
              <input
                type="number"
                name="interest_rate"
                value={editData.interest_rate || ""}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Collector Assigned:
              <select
                name="collector_assigned"
                value={editData.collector_assigned || ""}
                onChange={handleInputChange}
              >
                <option value="">Select Collector</option>
                {availableCollectors.map((collector) => (
                  <option key={collector.id} value={collector.id}>
                    {collector.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
  Approval Status:
  <select
    name="approval_status"
    value={editData.approval_status || ""}
    onChange={handleInputChange}
  >
    <option value="">Approval Status</option>
    <option value="accepted">Accepted</option>
    <option value="rejected">Rejected</option>
  </select>
</label>
            <div className="popup-actions">
              <button className="save-button" onClick={handleEditSubmit}>
                Save
              </button>
              <button className="cancel-button" onClick={closeEditPopup}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Popup */}
      {isDeletePopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Confirm Delete</h2>
            <p>Are you sure you want to delete this loan?</p>
            <div className="popup-actions">
              <button
                className="delete-confirm-button"
                onClick={() => handleDelete(currentLoan.id)}
              >
                Yes
              </button>
              <button className="cancel-button" onClick={closeDeletePopup}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanRequests;
